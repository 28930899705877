









































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import Recorder from "../lib/recorder";
import { convertDuration } from "./utils";
import { ImageSlider } from "@/components/common";
import VolumeGauge from "./volume-tester/Gauge.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import PresentationSelect from "./PresentationSelect.vue";
import LangSwitcher from "../components/LangSwitcher.vue";
import DeviceSelect from "../components/DeviceSelect.vue";
import VolumeTester from "./volume-tester/VolumeTester.vue";
import {
  ApiRecording,
  Presentation,
  Recording,
  SlideTimestamp,
} from "@/core/models";

@Component({
  components: {
    ImageSlider,
    LangSwitcher,
    VolumeTester,
    DeviceSelect,
    VolumeGauge,
    PresentationSelect,
  },
})
export default class AudioRecorder extends Vue {
  @PropSync("isRecordingProp") isRecording!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  // Tester
  volume = 0;
  showButton = true;
  stopTester = false;

  // Recorder
  duration = 0;
  uploading = false;
  recordList: Recording[] = [];
  recorder: Recorder | null = null;

  // Recorder locale
  recorderLocale = this.$i18n.locale as "en" | "de";
  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.recorderLocale = this.$i18n.locale as "en" | "de";
  }

  // muting/gain
  muted = false;
  toggleMuted() {
    this.recorder?.toggleMuted();
    this.muted = !this.muted;
  }

  // duration
  get durationFormatted() {
    const recorderDuration = this.duration || 0;
    return convertDuration(recorderDuration);
  }

  // recording button style
  get buttonStyle() {
    const border = `border: 1px solid ${
      this.isRecording ? "transparent" : "red"
    }`;
    const bgColor = `background-color: ${
      this.isRecording ? "red" : "transparent"
    }`;
    return [border, bgColor].join(";");
  }

  // upload
  async upload(record: Recording) {
    this.uploading = true;
    try {
      const data = new FormData();
      data.append("file", record.blob);
      data.append("title", record.title);
      data.append("locale", this.recorderLocale);
      data.append("recordedAt", record.recordedAt);
      data.append("timestamps", JSON.stringify(this.slideTimestamps));
      data.append("presentationId", JSON.stringify(this.selected?.ID || 0));
      data.append("type", "audio");

      const end = "/api/Recordings";
      const headers = { ContentType: "multipart/form-data" };
      const recording = (await api.post(end, data, {
        headers,
      })) as ApiRecording;

      const newRecord: Recording = {
        blob: null,
        audioBlob: null,
        videoBlob: null,
        type: "audio",
        id: recording.id,
        title: recording.title,
        audioURI: recording.audioURI,
        videoURI: recording.videoURI,
        locale: this.recorderLocale,
        recordedAt: recording.recordedAt,
        slideTimestamps: recording.slideTimestamps,
        presentationId: recording.presentationId,
      };

      this.slideTimestamps = [];
      this.$emit("created", newRecord);
    } catch (error) {
      console.log(error);
    }
    this.uploading = false;
  }

  // toggle (start/stop) and reset
  async toggleRecorder() {
    this.stopTester = true;
    if (!this.recorder) return;

    if (!this.isRecording) this.startRecorder();
    else this.stopRecorder();
  }
  async startRecorder() {
    this.showButton = false;
    const success = await this.recorder?.start();
    if (!success) {
      this.displaySnackbar("Could not start recorder");
      return;
    }
    this.isRecording = true;
    this.view = "pres";
    this.addSlide();
  }
  async stopRecorder() {
    this.showButton = true;
    if (!this.isRecording || !this.recorder) return;
    const record = await this.recorder.stop();
    if (record) this.upload(record);
    this.reset();
  }
  reset() {
    this.isRecording = false;
    this.shouldReset = true;
    this.view = "slide";
    this.duration = 0;
    this.volume = 0;
  }

  // Tip Dialog
  tipDialog = false;
  dontShowTip = false;
  closeTip() {
    if (this.dontShowTip) LocalStorage.setShouldShowRecordingTip(false);
    this.dontShowTip = true;
    this.tipDialog = false;
  }

  // Slides
  tsIndex = 0;
  slideIndex = 0;
  shouldReset = false;
  slideTimestamps: SlideTimestamp[] = [];
  @Prop({ default: () => null }) selected!: Presentation | null;
  @PropSync("viewMode") view!: "slide" | "pres";

  get images() {
    if (!this.selected) return [];
    return this.selected.Slides.map(x => x.Uri);
  }
  get maxWidth() {
    return this.view === "slide" ? "30vw" : "100%";
  }
  get maxHeight() {
    return this.view === "slide" ? "50vh" : "70vh";
  }
  addSlide() {
    if (!this.recorder || !this.selected) return;

    if (this.slideTimestamps.length > 0) {
      const lastTimestamp = this.slideTimestamps[
        this.slideTimestamps.length - 1
      ];
      lastTimestamp.duration = Math.max(
        this.duration * 1000 - lastTimestamp.offset,
        0,
      );
    }

    // Erstelle den neuen Timestamp
    const slide = this.selected.Slides[this.slideIndex];
    const ts: SlideTimestamp = {
      index: this.tsIndex,
      slideURI: slide.Uri,
      slideIndex: this.slideIndex,
      offset: this.duration * 1000,
      duration: 0,
    };

    this.slideTimestamps.push(ts);
    this.tsIndex++;
  }
  slideChanged(idx: number) {
    this.slideIndex = idx;
    this.addSlide();
  }
  @Watch("selected")
  selectedChanged() {
    this.slideIndex = 0;
    this.addSlide();
  }

  created() {
    window.onbeforeunload = () => {
      if (this.isRecording)
        return "The recording is still running... Are you sure you wanna exit?";
    };
  }
  mounted() {
    this.recorder = new Recorder(status => {
      this.volume = status.vol;
      this.duration = status.duration;
    });
    const showTip = LocalStorage.getShouldShowRecordingTip();
    this.dontShowTip = !showTip;
    if (showTip) this.tipDialog = true;
  }
  beforeDestroy() {
    this.stopRecorder();
    window.onbeforeunload = null;
  }
}
